import { transformerRegistry } from '@nextgen-web-framework/all';
import { EventNameEnum } from './types/propTypes';
import CartLinkTransformer from './transformers/CartLinkTransformer';
import ProductViewTransformer from './transformers/ProductViewTransformer';
import SearchViewTransformer from './transformers/SearchViewTransformer';
import ProductListViewTransformer from './transformers/ProductListViewTransformer';
import SharebarTransformer from './transformers/SharebarTransformer';
import { TEALIUM_TOPICS } from './utils/constant';

const updateTransformerRegistry = () => {
  transformerRegistry[TEALIUM_TOPICS.cart_addition] = CartLinkTransformer;
  transformerRegistry[TEALIUM_TOPICS.cart_remove] = CartLinkTransformer;
  transformerRegistry[EventNameEnum.product_view] = ProductViewTransformer;
  transformerRegistry[EventNameEnum.onsite_search] = SearchViewTransformer;
  transformerRegistry[EventNameEnum.product_list_view] = ProductListViewTransformer;
  transformerRegistry[TEALIUM_TOPICS.sharebar_publish] = SharebarTransformer;
  transformerRegistry[TEALIUM_TOPICS.onsite_search] = SearchViewTransformer;
};

export default updateTransformerRegistry;
