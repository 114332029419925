import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { cartSlice, myListSlice } from '@commerce-cart-webcomponents/sdk';
import { checkoutSlice } from '@commerce-checkout-webcomponents/sdk';
import { amshopSlice } from '@commerce-webcomponents/sdk';
import { searchSlice } from '@commerce-search-webcomponents/sdk';
import { orderHistorySlice } from '@commerce-order-webcomponents/sdk';
import { subscriptionSlice } from '@commerce-subscription-webcomponents/sdk';
import { profileSlice } from '@commerce-profile-webcomponents/sdk';
import { myShopSlice } from '@commerce-myshop-webcomponents/sdk';
import { identitySlice } from '@commerce-identity-webcomponents/sdk';

import { ApiConfigService } from '@nextgen-web-framework/core';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'; // eslint-disable-line

const rootReducer = combineReducers({
  [cartSlice.name]: cartSlice.reducer,
  [myListSlice.name]: myListSlice.reducer,
  [checkoutSlice.name]: checkoutSlice.reducer,
  [amshopSlice.name]: amshopSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [orderHistorySlice.name]: orderHistorySlice.reducer,
  [subscriptionSlice.name]: subscriptionSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [myShopSlice.name]: myShopSlice.reducer,
  [identitySlice.name]: identitySlice.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;

// preloadedState state is used for testing utility
export const makeStore = (
  preloadedState?: any, // eslint-disable-line
) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            BASE_API_URL: ApiConfigService.getBaseUrl(),
            CHECKOUT_BASE_API_URL: ApiConfigService.getBaseUrl(),
            CART_BASE_API_URL: ApiConfigService.getBaseUrl(),
            CMS_BASE_API_URL: ApiConfigService.getBaseUrl() + '/comcontentstackas',
            SEARCH_BASE_API_URL: ApiConfigService.getBaseUrl() + '/comsearchas',
            ORDER_BASE_API_URL: ApiConfigService.getBaseUrl() + '/comorderas',
          },
        },
        serializableCheck: false,
      }),
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

// hooks for TS
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
