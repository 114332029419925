import { CartEventDataInterface } from '@commerce-cart-webcomponents/sdk';

const CartLinkTransformer = <T>(data: T) => {
  const cartEventData = data as CartEventDataInterface;
  const transformedData = {
    cart_type: cartEventData.cart_type,
    product_brand: cartEventData.product_brand,
    product_businessVolume: cartEventData.product_businessVolume,
    product_id: cartEventData.product_id,
    product_name: cartEventData.product_name,
    product_pointValue: cartEventData.product_pointValue,
    product_unitPrice: cartEventData.product_unitPrice,
    product_units: cartEventData.product_units,
    product_promo: cartEventData.product_promo,
    product_referrer: cartEventData.product_referrer,
  };
  return transformedData;
};

export default CartLinkTransformer;
