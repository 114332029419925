import { useEffect } from 'react';
import { createEventListener, useTealiumDataLayer, TealiumScript } from '@nextgen-web-framework/all';
import { ConsoleLogger, EventService } from '@nextgen-web-framework/core';
import Head from 'next/head';
import TEALIUM_EVENT_TOPICS from './utils/constant';
import updateTransformerRegistry from './transformerMapping';
import updateLinkEventsMappingRegistry from './linkEventsMapping';

const TealiumSetup = () => {
  updateTransformerRegistry();
  updateLinkEventsMappingRegistry();
  const dataLayer = useTealiumDataLayer();
  useEffect(() => {
    const uTagLinkHandler = createEventListener(dataLayer);
    let token: string[];
    const subscribeEvents = () => {
      token = EventService.subscribeMultiple(TEALIUM_EVENT_TOPICS, uTagLinkHandler);
    };
    if (typeof window.utag !== 'undefined') {
      ConsoleLogger.log('CALLING UTAG VIEW'); // Logs just for temporary purpose, will remove it once utag view starts working
      window.utag.view(window.utag_data);
    }

    subscribeEvents();
    return () => {
      EventService.unsubscribeMultiple(token);
    };
  }, [dataLayer]);
  return (
    <>
      <Head>
        <script
          data-testid="tealium-utag-data"
          type="text/javascript"
          defer
          dangerouslySetInnerHTML={{
            __html: `window.utag_data = ${JSON.stringify(dataLayer)};`,
          }}
        ></script>
      </Head>
      <TealiumScript />
    </>
  );
};

export default TealiumSetup;
