/* eslint-disable  @typescript-eslint/no-explicit-any */
export type CMSTransformerDataType = any;
export enum CustomerType {
  ABO = 'BUSINESS_OWNER',
  MEMBER = 'MEMBER',
}

export interface CommonDataProps {
  data: CMSTransformerDataType;
  showError?: boolean;
}
export const SessionStorageKeys = {
  BANNER_DISPLAYED_TIME: 'bannerDisplayedTime',
};
export const LocalStorageKeys = {
  CHECKOUT_ID: 'checkoutId',
  CHECKOUT_VERSION: 'checkoutVersion',
  CART_ID: 'cartId',
  WAREHOUSE_ID: 'warehouseId',
  USER_NAME: 'userName',
  PARTY_ID: 'partyId',
  USER_TYPE: 'userType',
  SHARECODE_ATTRIBUTES: 'sharecodeAttributes',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  BANNER_DISPLAYED_TIME: 'bannerDisplayedTime',
};

export const CookieStorageKeys = {
  NEXT_LOCALE: 'NEXT_LOCALE',
};
// TODO: Implement a function to create the enum from userType.
//  Currently, there is an error with pub sub, so we'll revisit this once NFRONT-1480 is completed.
//  Tracking ticket: https://amwaycloud.atlassian.net/browse/NFRONT-1480
// Checking again in a few days for resolution.
export enum UserRole {
  ANONYMOUS = 1,
  ABO = ANONYMOUS << 1,
  REGISTERED = ABO << 1,
  MEMBER = REGISTERED << 1,
  EMPLOYEE = MEMBER << 1,
}

export enum ShopBy {
  OFFERS = 'offers',
}

export const AuthenticatedUser = UserRole.ABO | UserRole.REGISTERED | UserRole.MEMBER | UserRole.EMPLOYEE;

export enum PageCategory {
  EXPRESS = 'EXPRESS_PAGE',
  STANDARD = 'STANDARD_PAGE',
  DEFERRED = 'DEFERRED_PAGE',
}

export const STATUS_CODE = {
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export enum OrderPageType {
  ORDER_DETAIL_PAGE = 'order-details',
  GROUP_ORDER_DETAIL_PAGE = 'group-order',
  PAYMENT_PAGE = 'checkout-retry',
  ORDER_TRACKING_PAGE = 'order-tracking',
  RETURN_DETAIL_PAGE = 'return-details',
  DELIVERY_TRACKING = 'delivery-tracking',
}

export enum CMSBannerType {
  BANNER_WITH_CTA = 'BannerWithCta',
  VIDEO_BANNER_WITH_CTA = 'VideoBannerWithCta',
  BANNER_WITHOUT_CTA = 'BannerWithoutCta',
}

export enum BannerComponentType {
  HERO_BANNER = 'hero_banner',
  VIDEO_BANNER = 'video_banner',
  STANDARD_BANNER_WITH_NO_CTA = 'standard_banner_no_cta',
}

type ContentDetailType = {
  [key in ContentType]: {
    uid: string;
  };
};

export const contentDetailType: ContentDetailType = {
  resources: {
    uid: 'resource_detail_template',
  },
  promotions: {
    uid: 'promotion_detail_template',
  },
  articles: {
    uid: 'article_detail_template',
  },
};
export type ContentType = keyof typeof Content;

export enum Content {
  resources = 'resources',
  promotions = 'promotions',
  articles = 'articles',
}

export enum TENANT {
  THA = 'tha',
  GLOBAL = 'global',
}
