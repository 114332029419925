import { ReactNode, useState, createContext, useContext, useMemo } from 'react';
import { LayoutUtilityContextValue, Utility } from './types/propsTypes';

type Props = {
  children?: ReactNode;
};

const LayoutUtilityContext = createContext<LayoutUtilityContextValue>({
  utility: {},
  updateUtility: () => null,
});

const LayoutUtilityProvider = ({ children }: Props): JSX.Element => {
  const [utility, setUtility] = useState({});
  const updateUtility = (util: Utility) => {
    setUtility((prevUtility) => {
      const updatedUtility: Utility = {
        ...prevUtility,
        ...util,
      };
      return updatedUtility;
    });
  };
  const value = useMemo(
    () => ({
      utility: utility,
      updateUtility: updateUtility,
    }),
    [utility, updateUtility],
  );

  return (
    <>
      <LayoutUtilityContext.Provider value={value}>
        <>{children}</>
      </LayoutUtilityContext.Provider>
    </>
  );
};

export const useLayoutUtility = () => {
  const { utility, updateUtility }: LayoutUtilityContextValue = useContext(LayoutUtilityContext);
  return { ...utility, updateUtility };
};

export default LayoutUtilityProvider;
