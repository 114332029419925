declare global {
  interface Window {
    // eslint-disable-next-line
    utag: any;
    // eslint-disable-next-line
    utag_data: any;
  }
}

export type TransformerMapping = {
  [key: string]: <T>(data: T) => object;
};

export type LinkEventMapping = {
  [key: string]: string;
};

export enum EventNameEnum {
  cart_addition = 'cart_addition',
  cart_remove = 'cart_remove',
  product_view = 'product_view',
  onsite_search = 'onsite_search',
  abo_registration_start = 'abo_registration_start',
  abo_registration_complete = 'abo_registration_complete',
  customer_registration_start = 'customer_registration_start',
  customer_registration_complete = 'customer_registration_complete',
  member_registration_start = 'member_registration_start',
  member_registration_complete = 'member_registration_complete',
  product_list_view = 'product_list_view',
  sharebar_publish = 'share',
  shopping_list_addition = 'shopping_list_addition',
  shopping_list_remove = 'shopping_list_remove',
  proceed_to_checkout = 'checkout',
  cart_view = 'cart_view',
}

export enum RoutesEnum {
  HomePage = '/',
  CartPage = '/cart',
  OrderPage = 'order/[orderId]',
  SearchPage = '/search',
  PdpPage = '/product/[pid]',
  PlpPage = '/category/[categoryId]',
  SignUpPage = '/signup',
  OrderConfirmationPage = '/order/[orderId]',
}

export interface SubscriptionRequest {
  topic: string;
  version: string;
}

export type AuthenticatedSession = {
  [key: string]: number;
};
