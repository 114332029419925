import { Address } from '@commerce-checkout-webcomponents/sdk';
import { profileApi } from '@commerce-profile-webcomponents/sdk';
import type { CheckoutProfile } from '@commerce-checkout-webcomponents/sdk';
const getUpdatedCheckoutProfileWithDefaultAddress = async (updatedCheckoutProfileData: CheckoutProfile) => {
  // Expected in upcoming version: 'SHIPPING' to be exported by the profile team.
  const userAddressList = await profileApi.getShippingAddressList({ contactType: 'SHIPPING' });
  const defaultAddress: Address = userAddressList?.contacts?.find(
    (userAddress) => userAddress.isDefault ?? userAddressList.contacts[0],
  ) as Address;
  if (defaultAddress) {
    updatedCheckoutProfileData = {
      ...updatedCheckoutProfileData,
      fulfillmentType: {
        ...(updatedCheckoutProfileData.fulfillmentType ?? {}),
        serviceLevel: {
          ...(updatedCheckoutProfileData.fulfillmentType?.serviceLevel ?? {}),
          deliveryAddress: defaultAddress,
        },
      },
    };
  }
  return updatedCheckoutProfileData;
};

export default getUpdatedCheckoutProfileWithDefaultAddress;
