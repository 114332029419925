import { Logger, StorageService } from '@nextgen-web-framework/core';
import type { Session } from '@nextgen-web-framework/all';
import isEqual from 'lodash/isEqual';
import { LocalStorageKeys } from '../types/common';

const logger = new Logger();

const buildUserSessionFromCookie = (pair: string, userSession: Session) => {
  if (pair) {
    const [key, value] = pair.split('=');
    if ((key === 'act' || key === 'accounts') && value) {
      userSession[key] = JSON.parse(value);
    } else if (key === 'primaryProfile' && value) {
      userSession.primaryProfile = value === 'true';
    } else {
      userSession[key as keyof Omit<Session, 'act' | 'accounts' | 'primaryProfile'>] = value;
    }
  }
};
const getInitialSession = () => {
  let userSession: Session = {};
  const localstorage = StorageService.getLocalStorage();
  const cookie = StorageService.getCookieStorage();
  const userCookie = decodeURIComponent(cookie.retrieve('user-info') || '{}');
  logger.info('USER_COOKIE', `${userCookie}`);
  const warehouseId = localstorage.retrieve(LocalStorageKeys.WAREHOUSE_ID);
  if (userCookie.includes('id')) {
    userCookie.split(';').forEach((pair) => {
      buildUserSessionFromCookie(pair, userSession);
    });
  }
  warehouseId && (userSession['warehouseId'] = warehouseId);
  return userSession;
};

const compareSession = (cookieSession: Session, currentSession: Session) => {
  for (const key in cookieSession) {
    // TEMP FIX TO SKIP warehouseId from SessionRefresher subsequent re-renders
    if (key === 'warehouseId') {
      continue;
    }
    if (!isEqual(cookieSession[key as keyof Session], currentSession[key as keyof Session])) {
      return false;
    }
  }
  return true;
};
export default getInitialSession;

export { compareSession };
