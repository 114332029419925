import { Logger } from '@nextgen-web-framework/core';
import { cmsApi } from '@commerce-webcomponents/sdk';
import { CMSTransformerDataType } from '../types/common';
import { CONTENT_STACK } from './service-keys';
import { getRequestOptions } from './common';

interface ContentEssentialsProps {
  locale?: string;
  uId: string;
  params?: Array<Query>;
}
interface PageEssentialsProps {
  locale?: string;
  slug: string;
  template: string;
}

export interface Query {
  key: string;
  value: string;
}

export interface ContentPageProps {
  uId: string;
  url: string;
  params?: CMSTransformerDataType;
  slugName?: string;
}

const logger = new Logger();

const getContentEssentials = async ({ locale = 'en-us', uId, params }: ContentEssentialsProps) => {
  let data: CMSTransformerDataType;
  let showError = false;
  try {
    data = await cmsApi.fetchContent(
      {
        uId: uId,
        params,
      },
      {
        ...getRequestOptions(locale, CONTENT_STACK),
      },
    );
    logger.log(`CMS_UID: cms api data: ${uId} ${JSON.stringify(data)}`);
  } catch (err) {
    logger.log(`CMS_UID: failed with error: ${uId} ${JSON.stringify(err)}`);
    showError = true;
  }
  return {
    data: data ?? null,
    showError,
  };
};

const getPageEssentials = async ({ locale, slug, template }: PageEssentialsProps) => {
  let data: CMSTransformerDataType;
  let showError = false;
  try {
    data = await cmsApi.fetchPage(
      {
        slug: slug,
        template: template,
      },
      {
        ...getRequestOptions(locale, CONTENT_STACK),
      },
    );
  } catch (err) {
    logger.log(`CMS_ERROR: failed with error:`, JSON.stringify(err));
    showError = true;
  }
  return {
    data: data ?? null,
    showError,
  };
};

const getContentPageData = async ({ params, uId, url, slugName = 'slug' }: ContentPageProps) => {
  const userType = Array.isArray(params?.[slugName]) && params?.[slugName][params?.[slugName].length - 1];
  const { data: cmsData, showError } = await getContentEssentials({
    locale: params?.locale as string,
    uId: uId,
    params: [
      {
        key: 'query',
        value: JSON.stringify({
          url: url,
          ...(process.env.NEXT_PUBLIC_ENABLE_AMWAY_RESTRICTION && {
            'account_types.account_types': { $in: [userType] },
          }),
        }),
      },
    ],
  });

  let notFoundData = null;
  if (!Object.keys(cmsData).length) {
    const { data } = await getContentEssentials({ locale: params?.locale as string, uId: '404' });
    notFoundData = data;
  }

  return {
    data: cmsData,
    showError: showError,
    notFoundData: notFoundData,
  };
};

export { getContentEssentials, getPageEssentials, getContentPageData };
