import { useNextgenRouter } from '@nextgen-web-framework/all';
import { useEffect, useState } from 'react';
import { PageLoader } from '@commerce-webcomponents/ui';
import { LoaderProps } from './types/propsTypes';

const Loader = (props: LoaderProps) => {
  const router = useNextgenRouter();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let startTimer: NodeJS.Timeout;
    let isCancelled = false;
    const handleStart = (url: string) => {
      const regex = /^(https?:\/\/.*|.*\.xml)$/;
      const hasHash = url === window.location.pathname && window.location.hash;
      clearTimeout(startTimer);
      isCancelled = false;
      startTimer = setTimeout(() => {
        // Start loader ONLY when navigating to a relative url, non xml paths and non hash route
        if (!isCancelled && !regex.test(url) && !hasHash) {
          setLoading(true);
        }
      }, 200);
    };
    const handleComplete = () => {
      clearTimeout(startTimer);
      setLoading(false);
      isCancelled = true;
    };

    router.events?.on('routeChangeStart', handleStart);
    router.events?.on('routeChangeComplete', handleComplete);
    router.events?.on('routeChangeError', handleComplete);

    return () => {
      router.events?.off('routeChangeStart', handleStart);
      router.events?.off('routeChangeComplete', handleComplete);
      router.events?.off('routeChangeError', handleComplete);
    };
  }, []);

  return loading ? <PageLoader /> : <>{props.children}</>;
};

export default Loader;
