import { cmsApi } from '@commerce-webcomponents/sdk';
import { Logger, CurrencyFormatConfig, ConfigService } from '@nextgen-web-framework/core';
import { CurrencyInterface } from '@com.amway.commerce/webshop-currency-v1-prod/Schema';
import { BreadcrumbProps } from '@commerce-product-webcomponents/sdk';
import {
  identityLoginPageCmsDataTransformer,
  identityResetIdMigrationPageCmsDataTransformer,
} from '@commerce-identity-webcomponents/sdk';
import { getLocale } from '../i18n.config';
import { CMSTransformerDataType, PageCategory, CMSBannerType, BannerComponentType } from '../types/common';
import { getContentEssentials } from '../utils/getEssentials';
import { CONTENT_STACK } from './service-keys';
import logObject from './logObject';
import getServerUrl from './getServerUrl';
import getMasterData from './getMasterdata';

type TranslationFunction = (str: string, values?: Record<string, string>) => string;

const logger = Logger.getInstance(logObject('common.ts'));

function dragElement(elmnt: HTMLElement, onClick?: () => void) {
  let currentPosLeft = 0,
    currentPosTop = 0;
  let click = false;
  elmnt.ontouchmove = dragTouchMove;
  elmnt.onmousedown = dragMouseDown;

  function dragTouchMove(e: TouchEvent) {
    e.preventDefault();
    const [touchLocation] = Array.from(e.targetTouches);
    elmnt.style.left = `${touchLocation.clientX - elmnt.clientWidth / 2}px`;
    elmnt.style.top = `${touchLocation.clientY - elmnt.clientWidth / 2}px`;
    elmnt.style.bottom = 'unset';
  }

  function dragMouseDown(e: MouseEvent) {
    e.preventDefault();
    // get the mouse cursor position at startup:
    currentPosLeft = e.clientX;
    currentPosTop = e.clientY;
    click = true;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: MouseEvent) {
    e.preventDefault();
    // calculate the new cursor position:
    const newPosLeft = currentPosLeft - e.clientX;
    const newPosTop = currentPosTop - e.clientY;
    currentPosLeft = e.clientX;
    currentPosTop = e.clientY;
    // set the element's new position:
    elmnt.style.top = `${elmnt.offsetTop - newPosTop}px`;
    elmnt.style.left = `${elmnt.offsetLeft - newPosLeft}px`;
    elmnt.style.bottom = 'unset';
    click = false;
  }

  function closeDragElement() {
    if (click) {
      onClick?.();
    }
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

const getAccountContent = async (locale: string) => {
  let dashboardAllUserData = null;
  const pageContent = await getContentEssentials({
    uId: 'account',
    locale,
  });
  const { data } = pageContent;
  let { showError } = pageContent;
  try {
    dashboardAllUserData = await cmsApi.fetchAllContent(
      {
        uId: 'account_dashboard',
      },
      {
        ...getRequestOptions(locale, CONTENT_STACK),
      },
    );
  } catch (err) {
    logger.error('My Account Page Error', err);
    showError = true;
  }

  return {
    data,
    dashboardAllUserData: dashboardAllUserData ?? null,
    showError,
  };
};

const getAccountDashboardData = (accountDashboardAllUserData: CMSTransformerDataType, userType?: string) => {
  const accountDashboardData: CMSTransformerDataType = accountDashboardAllUserData?.find(
    (item: CMSTransformerDataType) => item.account_type.account_type === userType,
  );
  return accountDashboardData;
};

const getMyAccountTabTitle = (accountDashboardData: CMSTransformerDataType, url: string) => {
  const accountTab = accountDashboardData?.items.find((item: CMSTransformerDataType) => url.includes(item.link.url));
  return accountTab?.title;
};

const getCurrencyConfig = (currency: string, locale: string): CurrencyFormatConfig | undefined => {
  let currencyFormatConfig;
  const currencyConfigData = getMasterData(
    '@com.amway.commerce/webshop-currency-v1-prod',
    locale,
  ) as unknown as CurrencyInterface[];
  const currencyData = currencyConfigData?.find((c) => c.pk === currency);
  if (currencyData) {
    const localeCurrencyData = currencyData.locales.find((l) => l.locale === locale);
    currencyFormatConfig =
      localeCurrencyData &&
      ({
        [currency]: localeCurrencyData,
      } as unknown as CurrencyFormatConfig);
  }
  return currencyFormatConfig;
};

const getDefaultAccountBreadcrumbs = (t: TranslationFunction, pathname: string): BreadcrumbProps => {
  const breadcrumbData = [
    {
      name: t('web.bottomTabNavigation.home'),
      link: '/',
    },
    {
      name: t('amshop.accountDashboard.myAccount'),
      link: '/my-account',
    },
  ];

  if (pathname.includes('settings-preferences')) {
    breadcrumbData.push({
      name: t('amshop.settingPreferences'),
      link: '/my-account/settings-preferences',
    });
  }

  if (pathname.includes('payment-management')) {
    breadcrumbData.push({
      name: t('amshop.paymentManagement'),
      link: '/my-account/payment-management',
    });
  }
  if (pathname.includes('bank-account')) {
    breadcrumbData.push({
      name: t('amshop.bankAccount'),
      link: '/my-account/payment-management/bank-account',
    });
  }

  return { breadcrumbData };
};

const getHomeBreadcrumb = (t: TranslationFunction) => ({
  name: t('web.bottomTabNavigation.home'),
  link: '/',
});

const getLoginPageData = async (locale: string | undefined, loginPage = true) => {
  let bannerImgUrl = null;
  const pageContent = await getContentEssentials({
    uId: 'login',
    locale: locale,
  });
  const { data } = pageContent;
  let { showError } = pageContent;
  try {
    const imgUrl = loginPage
      ? identityLoginPageCmsDataTransformer(data)
      : identityResetIdMigrationPageCmsDataTransformer(data);
    bannerImgUrl = imgUrl.banner_img_src;
  } catch (err) {
    showError = true;
  }
  return {
    props: {
      data,
      bannerImgUrl,
      showError,
    },
  };
};

const getInvalidateTime = (PageCategoryType: string) => {
  switch (PageCategoryType) {
    case PageCategory.DEFERRED:
      return Number(process.env.NEXT_PUBLIC_DEFERRED_REVALIDATION_INTERVAL);
    case PageCategory.STANDARD:
      return Number(process.env.NEXT_PUBLIC_STANDARD_REVALIDATION_INTERVAL);
    default:
      return Number(process.env.NEXT_PUBLIC_EXPRESS_REVALIDATION_INTERVAL);
  }
};

const getRedirectsRefreshInterval = () => {
  return Number(process.env.NEXT_PUBLIC_DEFERRED_REVALIDATION_INTERVAL) * 1000;
};

const getBannerComponentType = (bannerType: string): string => {
  let componentType = '';
  switch (bannerType) {
    case CMSBannerType.BANNER_WITH_CTA:
      componentType = BannerComponentType.HERO_BANNER;
      break;
    case CMSBannerType.VIDEO_BANNER_WITH_CTA:
      componentType = BannerComponentType.VIDEO_BANNER;
      break;
    case CMSBannerType.BANNER_WITHOUT_CTA:
      componentType = BannerComponentType.STANDARD_BANNER_WITH_NO_CTA;
      break;
    default:
      break;
  }
  return componentType;
};

const getRequestOptions = (locale?: string, serviceKey?: string) => {
  const mappedLocale = getLocale(locale as string);
  return {
    ...(typeof window === 'undefined' && serviceKey && { baseURL: getServerUrl(serviceKey) }),
    ...(mappedLocale && {
      headers: {
        ['Accept-Language']: mappedLocale,
      },
    }),
  };
};

const getSignUpUrl = () => {
  const configs = ConfigService.getConfigs();
  const redirectUrl = configs.NEXT_PUBLIC_REGISTER_REDIRECT_URL ?? '/complete-your-profile';
  return `/signup?flowName=${configs.NEXT_PUBLIC_REGISTER_FLOW}&returnUri=${redirectUrl}`;
};

export {
  dragElement,
  getAccountContent,
  getAccountDashboardData,
  getMyAccountTabTitle,
  getCurrencyConfig,
  getDefaultAccountBreadcrumbs,
  getHomeBreadcrumb,
  getLoginPageData,
  getInvalidateTime,
  getBannerComponentType,
  getRequestOptions,
  getSignUpUrl,
  getRedirectsRefreshInterval,
};
