import {
  RegistrationIdVerificationStep,
  RegistrationPersonalDetailsStep,
  RegistrationSelectAccountType,
  RegistrationSponsor,
  RegistrationUserDetails,
  RegistrationUsernameVerification,
  SinglePageRegistrationStep,
} from '@commerce-profile-webcomponents/ui';
import { CheckoutTaxAndContact } from '@commerce-checkout-webcomponents/ui';
import CoverBanner from '../components/CoverBanner/CoverBanner';
import ContentSlotBanner from '../components/CoverBanner/types/propTypes';
const mappings = {
  E2E: {
    [ContentSlotBanner.COVER_BANNER]: CoverBanner,
  },
  profile: {
    SelectAccountTypeStep: RegistrationSelectAccountType,
    UserDetailsStep: RegistrationUserDetails,
    ContactVerificationStep: RegistrationUsernameVerification,
    selectSponsorStep: RegistrationSponsor,
    SinglePageStep: SinglePageRegistrationStep,
    idStep: RegistrationIdVerificationStep,
    UserDetailsStepTh: RegistrationPersonalDetailsStep,
  },
  checkout: {
    CheckoutTaxAndContact: CheckoutTaxAndContact,
    CheckoutTaxes: () => <></>,
    CheckoutLineItemLatam: () => <></>,
  },
};

export default mappings;
