import type { AppProps } from 'next/app';
import Image from 'next/image';
import Head from 'next/head';
import { FC, useCallback, useRef, useEffect, useState } from 'react';
import {
  LinkProvider,
  TranslationProvider,
  BreakpointProvider,
  ToastProvider,
  SessionProvider,
  ContentSlotProvider,
  NextgenImageProvider,
  NextgenRouterProvider,
  MasterDataProvider,
  ConfigProvider,
} from '@nextgen-web-framework/all';
import { AccountError } from '@commerce-identity-webcomponents/ui';
import { useRouter, usePathname, useParams, useSearchParams } from 'next/navigation';
import type { MasterDataEntries, Session, ReadonlyURLSearchParams } from '@nextgen-web-framework/all';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import {
  LoaderProvider,
  getCurrentAddress,
  AddressProvider,
  ErrorCard,
  Errors,
  SharecodeProvider,
} from '@commerce-webcomponents/ui';
import { ApiConfigService, PreferenceService, Logger, ConfigService } from '@nextgen-web-framework/core';
import type { PrefernceType } from '@nextgen-web-framework/core';
import Link from 'next/link';
import getConfig from 'next/config';
import { getLocale, i18n } from '../i18n.config';
import Messages from '../locale/messages.json';
import { AppStore, makeStore } from '../feature/store';
import '../styles/globalStyle.scss';
import SessionRefresher from '../components/SessionRefresher/SessionRefresher';
import UserCheckoutProfile from '../components/UserCheckoutProfile/UserCheckoutProfile';
import mappings from '../content-slot-mappings/content-slot-mappings-resolved';
import TealiumSetup from '../components/Tealium/TealiumSetup';
import Loader from '../components/Loader/Loader';
import getInitialSession from '../utils/getInitialSession';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import Impersonation from '../components/Impersonation/Impersonation';
import MultipleAccountSelector from '../components/MultipleAccountSelector/MultipleAccountSelector';
import LayoutUtilityProvider from 'components/LayoutUtilityProvider/LayoutUtilityProvider';
import { getCurrencyConfig } from 'utils/common';
import ScreenBanner from 'components/CoverBanner/ScreenBanner';
import DataDomeTag from 'components/DataDome/DataDomeTag';
import logObject from 'utils/logObject';
import getMasterData from 'utils/getMasterdata';
import { CMSTransformerDataType } from 'types/common';
import { checkIsIOS } from 'utils/viewportMeta';
import NextgenIntlProviderWrapper from 'components/NextgenIntlProviderWrapper/NextgenIntlProviderWrapper';

// Adds Query Data TO Generic Router Params
const queryStringToJson = (params: ReadonlyURLSearchParams | null): { [key: string]: string } => {
  if (params) {
    const json: { [key: string]: string } = {};
    params.forEach((value, key) => {
      json[key] = value;
    });
    return json;
  }
  return {};
};

const { publicRuntimeConfig } = getConfig();
ConfigService.setConfigs({
  ...publicRuntimeConfig,
  NEXT_PUBLIC_LANGUAGE: i18n.defaultLocale,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  DATADOME_JS_KEY: process.env.DATADOME_JS_KEY,
});
ApiConfigService.setTuples({
  'X-Amway-Tenant': process.env.NEXT_PUBLIC_TENANT_CODE as string,
  'X-Amway-Country': publicRuntimeConfig.NEXT_PUBLIC_COUNTRY,
  'X-Amway-Channel': publicRuntimeConfig.NEXT_PUBLIC_CHANNEL,
});

const logger = Logger.getInstance(logObject('_app.tsx'));

const updateMasterData = (locale: string) => ({
  CartConfig: getMasterData('@com.amway.commerce/cart-cartconfig-v1-prod', locale),
  RegistrationFlowConfigList: getMasterData('@com.amway.commerce/profile-registration-flow-config-v1-prod', locale),
  MasterCountryList: getMasterData('@com.amway.commerce/masterdata-country-v1-draft', locale),
  IdentityConfig: getMasterData('@com.amway.commerce/identity-identity-config-v1-prod', locale),
  IdentityUsernameConfig: getMasterData('@com.amway.commerce/identity-username-config-v1-prod', locale),
  ContactMasterData: getMasterData('@com.amway.commerce/profile-contactmasterdata-v1-prod', locale),
  MyAccountProfileConfig: getMasterData('@com.amway.commerce/profile-myaccountprofile-v1-prod', locale),
  MyAccountAddressConfig: getMasterData('@com.amway.commerce/profile-myaccountaddress-v1-prod', locale),
  RegistrationFieldsMasterData: getMasterData('@com.amway.commerce/profile-registrationvalidations-v1-prod', locale),
  MyShopMasterData: getMasterData('@com.amway.commerce/abomonetization-myshop-masterdata-v1-prod', locale),
  WebshopConfigs: getMasterData('@com.amway.commerce/webshop-configs-v1-prod', locale),
});

const MyApp: FC<AppProps & { err?: Error }> = ({ Component, ...props }) => {
  const storeRef = useRef<AppStore>();

  if (!storeRef.current) {
    //Create the store instance the first time this renders
    storeRef.current = makeStore();
  }

  const router = useRouter();
  const params = useParams();
  const query = useSearchParams() as unknown as ReadonlyURLSearchParams | null;
  const pathname = usePathname();
  // TODO: Will update the name error to AccountError once backend team has updated the name in queries.
  const errorAccountLogin = query?.get('accounterror');
  const errorLogin = query?.get('error');
  const selectedAccount = query?.get('hasSelectedAccount');

  const updatedParams = { ...params, locale: params?.locale || props.pageProps.locale };
  const mappedLocale = getLocale(updatedParams.locale);
  const routerObj = {
    pathname,
    params: { ...updatedParams, ...queryStringToJson(query) },
    query,
    ...router,
  };

  const [masterData, setMasterData] = useState<MasterDataEntries>(updateMasterData(mappedLocale));

  useEffect(() => {
    setMasterData(updateMasterData(mappedLocale));
  }, [mappedLocale]);

  const renderComponent = () => <Component {...props.pageProps} />;
  const renderErrorComponent = (errorType: string) => (
    <>
      <Component {...props.pageProps} />
      <AccountError errorType={errorType} onCloseCallback={() => routerObj.push('/')} />
    </>
  );

  const renderErrorCard = () => {
    const handleErrorCardClick = () => routerObj.refresh();
    const handleAccountErrorClick = (errorType: string) => renderErrorComponent(errorType);

    if (props.pageProps?.showError || props?.err) {
      return <ErrorCard type={Errors.General} className="error-card-wrapper" onActionClick={handleErrorCardClick} />;
    } else if (errorAccountLogin === 'true' || errorLogin === 'true') {
      return (
        <ErrorCard type={Errors.General} className="error-card-wrapper" onActionClick={() => routerObj.push('/')} />
      );
    }

    return handleAccountErrorClick(errorAccountLogin ?? errorLogin ?? '');
  };
  logger.log('GOOGLE_MAPS_API_KEY', process.env.GOOGLE_MAPS_API_KEY);
  ApiConfigService.addTuple('Accept-Language', mappedLocale ?? i18n.defaultLocale);
  let localeConfig: PrefernceType = {
    locale: '',
    currency: '',
    devMode: false, //make it true while local development
  };
  let initialSession: Session = {};
  let initialAddress = '';
  let initialShippingType = '';
  let initialAddressToDisplay = '';
  let initialPostalCodeToDisplay = '';
  if (typeof window !== 'undefined') {
    const baseUrl = window.origin;
    ApiConfigService.setBaseUrl(`${baseUrl}/api`);
    ConfigService.setConfigs({
      ...publicRuntimeConfig,
      NEXT_PUBLIC_WEB_BASE_PATH: baseUrl,
      NEXT_ISR_API_BASE_PATH: baseUrl,
      NEXT_PUBLIC_BASE_PATH: baseUrl,
      NEXT_PUBLIC_API_BASE_PATH: baseUrl,
      NEXT_PUBLIC_LANGUAGE: i18n.defaultLocale,
      GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
      DATADOME_JS_KEY: process.env.DATADOME_JS_KEY,
      NEXT_PUBLIC_OIDC_URL: `${baseUrl}${process.env.NEXT_PUBLIC_LOCAL_MACHINE ? '' : '/api'}${
        publicRuntimeConfig.NEXT_PUBLIC_OIDC_API_URL
      }`,
    });
    initialSession = getInitialSession();
    const { currentAddress, shippingType, addressToDisplay, postalCodeToDisplay } = getCurrentAddress();
    initialShippingType = shippingType;
    initialAddress = currentAddress;
    initialAddressToDisplay = addressToDisplay;
    initialPostalCodeToDisplay = postalCodeToDisplay;
  }
  if (routerObj.params?.locale) {
    localeConfig = {
      locale: mappedLocale,
      // TODO: Will be picked dynamically once we have provision to map currency
      currency: publicRuntimeConfig.NEXT_PUBLIC_CURRENCY,
      devMode: false, // change it to true while local development
    };
    const currencyFormatConfig = getCurrencyConfig(localeConfig.currency, localeConfig.locale);
    if (currencyFormatConfig) {
      localeConfig.currencyFormatConfig = currencyFormatConfig;
    }
    ApiConfigService.addTuple('Accept-Language', mappedLocale ?? i18n.defaultLocale);
  }
  PreferenceService.setPreferences({ ...localeConfig });
  const linkElement = useCallback((appProps: CMSTransformerDataType) => <Link {...appProps} prefetch={false} />, []);

  const logError = (error: Error, info: { componentStack: string }) => {
    logger.log('ERROR_COMPONENT_STACK', info.componentStack);
    logger.log('CLIENT_SIDE_ERROR', error);
  };

  useEffect(() => {
    const className = routerObj.params.locale as string;
    document.body.classList.add(className);
    return () => {
      document.body.classList.remove(className);
    };
  }, [routerObj.params.locale]);

  const ResolvedIntlProvider = publicRuntimeConfig.NEXT_PUBLIC_ENABLE_MASTER_DATA_CMS_LOCALIZATION
    ? NextgenIntlProviderWrapper
    : IntlProvider;

  return (
    <>
      {checkIsIOS() && (
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
        </Head>
      )}
      <ConfigProvider config={{ ...publicRuntimeConfig, NEXT_PUBLIC_LANGUAGE: i18n.defaultLocale }}>
        <BreakpointProvider>
          <ResolvedIntlProvider
            locale={mappedLocale}
            messages={mappedLocale ? Messages[mappedLocale as keyof typeof Messages] : {}}
          >
            <TranslationProvider
              fallbackMessages={Messages[publicRuntimeConfig.NEXT_FALLBACK_LANGUAGE as keyof typeof Messages]}
            >
              <NextgenRouterProvider method={routerObj}>
                <LinkProvider element={linkElement}>
                  <ErrorBoundary
                    onError={logError}
                    fallback={
                      <ErrorCard
                        type={Errors.General}
                        className="error-card-wrapper"
                        onActionClick={() => routerObj.refresh()}
                      />
                    }
                  >
                    <ContentSlotProvider slotMappings={mappings}>
                      <MasterDataProvider masterData={masterData}>
                        <AddressProvider
                          initialAddress={initialAddress}
                          initialShippingType={initialShippingType}
                          initialAddressToDisplay={initialAddressToDisplay}
                          initialPostalCodeToDisplay={initialPostalCodeToDisplay}
                        >
                          <SessionProvider session={initialSession}>
                            <Provider store={storeRef.current}>
                              <NextgenImageProvider element={Image} useCDNForCS={true}>
                                <ToastProvider portalContainerId="react-portal-toast">
                                  <SessionRefresher initialSession={initialSession}>
                                    <UserCheckoutProfile>
                                      <MultipleAccountSelector isAccountSelected={selectedAccount ?? ''} />
                                      <LayoutUtilityProvider>
                                        <TealiumSetup />
                                        <DataDomeTag />
                                        <PrivacyPolicy />
                                        <Impersonation>
                                          <Loader>
                                            <LoaderProvider>
                                              <SharecodeProvider>
                                                <ScreenBanner />
                                                {props.pageProps?.showError ||
                                                props?.err ||
                                                errorLogin ||
                                                errorAccountLogin
                                                  ? renderErrorCard()
                                                  : renderComponent()}
                                              </SharecodeProvider>
                                            </LoaderProvider>
                                          </Loader>
                                        </Impersonation>
                                      </LayoutUtilityProvider>
                                    </UserCheckoutProfile>
                                  </SessionRefresher>
                                </ToastProvider>
                              </NextgenImageProvider>
                            </Provider>
                          </SessionProvider>
                        </AddressProvider>
                      </MasterDataProvider>
                    </ContentSlotProvider>
                  </ErrorBoundary>
                </LinkProvider>
              </NextgenRouterProvider>
            </TranslationProvider>
          </ResolvedIntlProvider>
        </BreakpointProvider>
      </ConfigProvider>
    </>
  );
};

export default MyApp;
