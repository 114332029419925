import { useTranslation, useToast, Theme, Notification, IconStyle, useNextgenRouter } from '@nextgen-web-framework/all';
import { useEffect } from 'react';
import { i18n } from 'i18n.config';
const PrivacyPolicy = () => {
  const { addToast, deleteToast } = useToast();
  const router = useNextgenRouter();
  const toastId = 'privacy_policy_notification_toast';
  const t = useTranslation();
  const toastMsg = t('amshop.privacyPolicy.notification.title');
  const toastDescription = t('amshop.privacyPolicy.notification.description');
  const showToastNotification = () => {
    addToast({
      id: toastId,
      timeout: 3000,
      content: (
        <Notification
          onCloseClick={() => deleteToast(toastId)}
          customIcon={{ iconName: 'shield-check', iconStyle: IconStyle.SOLID, theme: Theme.BLUE }}
          hasBoldText={true}
          theme={Theme.ACTION}
          description={toastDescription}
          message={toastMsg}
        />
      ),
    });
  };
  const cookiePolicyHandler = () => {
    const url =
      router.params?.locale !== i18n.defaultLocale
        ? `/${router.params?.locale}/landing/cookies-policy`
        : '/landing/cookies-policy';
    window.open(url);
  };
  useEffect(() => {
    document.addEventListener('privacyModalRequest', cookiePolicyHandler);
    document.addEventListener('acceptAllRequest', showToastNotification);
    document.addEventListener('rejectAllRequest', showToastNotification);
    document.addEventListener('savePreferencesRequest', showToastNotification);
    return () => {
      document.removeEventListener('privacyModalRequest', cookiePolicyHandler);
      document.removeEventListener('acceptAllRequest', showToastNotification);
      document.removeEventListener('rejectAllRequest', showToastNotification);
      document.removeEventListener('savePreferencesRequest', showToastNotification);
    };
  }, [router.params?.locale]);

  return null;
};

export default PrivacyPolicy;
