import { FullScreenBanner } from '@commerce-webcomponents/ui';
import { Logger, StorageService } from '@nextgen-web-framework/core';
import { useEffect, useState } from 'react';
import { useAppSelector, AppState } from '../../feature/store';
import { SessionStorageKeys, LocalStorageKeys } from 'types/common';
import logObject from 'utils/logObject';

const logger = Logger.getInstance(logObject('CoverBanner.tsx', 'CoverBanner'));

const CoverBanner = () => {
  const [showFullScreenBanner, setShowFullScreenBanner] = useState(false);
  const sessionstorage = StorageService.getSessionStorage();
  const localstorage = StorageService.getLocalStorage();
  const { bannerData } = useAppSelector((state: AppState) => state.amshop);

  const checkBannerDisplayStatus = (showUpAgainFlag: boolean, bannerStartTime: number, bannerEndTime: number) => {
    let displayedTime = 0;
    if (showUpAgainFlag) {
      displayedTime = +sessionstorage.retrieve(SessionStorageKeys.BANNER_DISPLAYED_TIME);
    } else {
      displayedTime = +localstorage.retrieve(LocalStorageKeys.BANNER_DISPLAYED_TIME);
    }
    if (bannerStartTime < displayedTime && bannerEndTime > displayedTime) {
      return true;
    }
    sessionstorage.remove(SessionStorageKeys.BANNER_DISPLAYED_TIME);
    localstorage.remove(LocalStorageKeys.BANNER_DISPLAYED_TIME);
    return false;
  };

  const storeBannerDisplayStatus = (showUpAgainFlag: boolean, displayTime: number) => {
    if (showUpAgainFlag) {
      sessionstorage.store(SessionStorageKeys.BANNER_DISPLAYED_TIME, displayTime);
    } else {
      localstorage.store(LocalStorageKeys.BANNER_DISPLAYED_TIME, displayTime);
    }
  };

  const validateFullScreenBannerData = () => {
    try {
      if (Object.keys(bannerData).length === 0) {
        return;
      }
      // USING LOCAL STORAGE FOR SHOW ONLY ONCE & SESSION STORAGE FOR SHOW AGAIN IN SESSION CONDITIONS
      const displayTime = new Date().getTime();
      const bannerEndTime = new Date(bannerData.end_date).getTime();
      const bannerStartTime = new Date(bannerData.start_date).getTime();
      // Check if display time is not in banner start and end time window
      if (bannerEndTime < displayTime || bannerStartTime > displayTime) {
        return;
      }
      const showUpAgainFlag = bannerData.show_up_again;
      if (checkBannerDisplayStatus(showUpAgainFlag, bannerStartTime, bannerEndTime)) {
        return;
      }

      // Above conditions haven't met so we are going to show banner, so store their status in storage
      storeBannerDisplayStatus(showUpAgainFlag, displayTime);
      // SHOW FULL SCREEN BANNER
      setShowFullScreenBanner(true);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    validateFullScreenBannerData();
  }, [bannerData]);

  return (
    <>
      {showFullScreenBanner ? (
        <div id="full-screen-banner-container-id">
          <FullScreenBanner content={bannerData.content} componentType="page_banner" />
        </div>
      ) : null}
    </>
  );
};

export default CoverBanner;
