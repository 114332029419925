import Head from 'next/head';
import { ConfigService } from '@nextgen-web-framework/core';

const DataDomeTag = () => {
  const configs = ConfigService.getConfigs();
  const datadomeKey = configs.DATADOME_JS_KEY;
  const NEXT_PUBLIC_DATADOME_OVERRIDE_COOKIE_DOMAIN =
    (configs.NEXT_PUBLIC_DATADOME_OVERRIDE_COOKIE_DOMAIN as boolean) ?? false;

  return (
    <Head>
      <script
        data-testid="data-dome-test-id"
        type="text/javascript"
        defer
        dangerouslySetInnerHTML={{
          __html: `!function(a,b,c,d,e,f){a.ddjskey=e;a.ddoptions=f||null;var m=b.createElement(c),n=b.getElementsByTagName(c)[0];m.async=1,m.src=d,n.parentNode.insertBefore(m,n)}(window,document,"script","https://js.datadome.co/tags.js","${datadomeKey}", { ajaxListenerPath : true, overrideCookieDomain : ${NEXT_PUBLIC_DATADOME_OVERRIDE_COOKIE_DOMAIN} });`,
        }}
      ></script>
    </Head>
  );
};

export default DataDomeTag;
