import data1 from '@com.amway.commerce/abomonetization-myshop-masterdata-v1-prod/data.json'; 
import data2 from '@com.amway.commerce/cart-cartconfig-v1-prod/data.json'; 
import data3 from '@com.amway.commerce/identity-identity-config-v1-prod/data.json'; 
import data4 from '@com.amway.commerce/identity-username-config-v1-prod/data.json'; 
import data5 from '@com.amway.commerce/masterdata-country-v1-draft/data.json'; 
import data6 from '@com.amway.commerce/masterdata-country-v1-draft/data_th-th.json'; 
import data7 from '@com.amway.commerce/profile-contactmasterdata-v1-prod/data.json'; 
import data8 from '@com.amway.commerce/profile-myaccountaddress-v1-prod/data-tha.json'; 
import data9 from '@com.amway.commerce/profile-myaccountprofile-v1-prod/data-tha.json'; 
import data10 from '@com.amway.commerce/profile-registration-flow-config-v1-prod/data-tha.json'; 
import data11 from '@com.amway.commerce/profile-registrationvalidations-v1-prod/data-tha.json'; 
import data12 from '@com.amway.commerce/webshop-currency-v1-prod/data.json'; 
import data13 from '@com.amway.commerce/webshop-configs-v1-prod/data-tha.json';  
  
const masterDataFactory = { 
    '@com.amway.commerce/abomonetization-myshop-masterdata-v1-prod/data.json': data1,
    '@com.amway.commerce/cart-cartconfig-v1-prod/data.json': data2,
    '@com.amway.commerce/identity-identity-config-v1-prod/data.json': data3,
    '@com.amway.commerce/identity-username-config-v1-prod/data.json': data4,
    '@com.amway.commerce/masterdata-country-v1-draft/data.json': data5,
    '@com.amway.commerce/masterdata-country-v1-draft/data_th-th.json': data6,
    '@com.amway.commerce/profile-contactmasterdata-v1-prod/data.json': data7,
    '@com.amway.commerce/profile-myaccountaddress-v1-prod/data-tha.json': data8,
    '@com.amway.commerce/profile-myaccountprofile-v1-prod/data-tha.json': data9,
    '@com.amway.commerce/profile-registration-flow-config-v1-prod/data-tha.json': data10,
    '@com.amway.commerce/profile-registrationvalidations-v1-prod/data-tha.json': data11,
    '@com.amway.commerce/webshop-currency-v1-prod/data.json': data12,
    '@com.amway.commerce/webshop-configs-v1-prod/data-tha.json': data13
};
  

export default masterDataFactory;