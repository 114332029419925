import { ReactNode } from 'react';
import { useSession, useNextgenRouter } from '@nextgen-web-framework/all';
import { Logger, request, HTTPVerbs } from '@nextgen-web-framework/core';
import type { RequestOptions } from '@nextgen-web-framework/core';
import { ImpersonationBanner } from '@commerce-webcomponents/ui';
import { identityApi } from '@commerce-identity-webcomponents/sdk';
import logObject from 'utils/logObject';

const logger = Logger.getInstance(logObject('Impersonation.tsx', 'Impersonation'));

const Impersonation = ({ children }: { children: ReactNode }) => {
  const { session, isActor } = useSession();
  const router = useNextgenRouter();

  const actorName = session?.act ? `${session?.act?.givenName} ${session?.act?.familyName}` : '';

  const endSessionHandler = async () => {
    const configs = ConfigService.getConfigs();
    const baseUrl = ApiConfigService.getBaseUrl();

    try {
      const postAuthUrl = btoa(JSON.stringify({ redirectUri: `${configs.NEXT_PUBLIC_WEB_BASE_PATH}/logout?close` }));
      const postLogoutRedirectUri = `${baseUrl}/comgluuoidc/v1/logout?state=${postAuthUrl}`;
      const logoutRes = await identityApi.getLogoutUrl({
        gluuBaseUrl: `${configs.NEXT_PUBLIC_GLU_BASE_URL}`,
        postLogoutRedirectUri,
      });
      router?.replace(logoutRes);
    } catch (error) {
      logger.error(error);
    }
  };

  const extendSessionHandler = async () => {
    try {
      const extendSession: RequestOptions = {
        url: `/comgluuoidc/v1/extend-session`,
        method: HTTPVerbs.POST,
        retries: 0,
      };
      await request(extendSession);
      router?.refresh();
    } catch (err) {
      logger.error(err, 'Error Occured');
    }
  };

  return isActor() ? (
    <div>
      <ImpersonationBanner
        actorName={actorName}
        aboNum={session?.aboNum ?? ''}
        timeoutAlertInSeconds={Number(process.env.NEXT_PUBLIC_IMPERSONATION_EXTEND_INTERVAL ?? '0')}
        endSessionCallback={endSessionHandler}
        extendSessionCallback={extendSessionHandler}
      />
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default Impersonation;
