import { CartEventsEnum, CartEventsVersionsEnum } from '@commerce-cart-webcomponents/sdk';
import { ProfileEventsEnum } from '@commerce-profile-webcomponents/sdk';
import { ShareEventsEnum, ShareEventsVersionsEnum } from '@commerce-sharebar-webcomponents/ui';
import { SearchEventsEnum, SearchEventsVersionsEnum } from '@commerce-search-webcomponents/sdk';
import { SubscriptionRequest } from '../types/propTypes';

export const TEALIUM_TOPICS = {
  cart_addition: `cart.domain.${CartEventsEnum.AddToCart}`,
  cart_remove: `cart.domain.${CartEventsEnum.RemoveCartEntry}`,
  cart_view: `cart.domain.${CartEventsEnum.ViewCart}`,
  shopping_list_addition: `cart.domain.${CartEventsEnum.AddToWishlist}`,
  shopping_list_remove: `cart.domain.${CartEventsEnum.RemoveWishlistEntry}`,
  proceed_to_checkout: `cart.domain.${CartEventsEnum.ProceedToCheckout}`,
  abo_registration_start: `profile.domain.${ProfileEventsEnum.AboRegistrationStart}`,
  customer_registration_start: `profile.domain.${ProfileEventsEnum.CustomerRegistrationStart}`,
  member_registration_start: `profile.domain.${ProfileEventsEnum.MemberRegistrationStart}`,
  sharebar_publish: `web.domain.${ShareEventsEnum.SHARE}`,
  onsite_search: `search.domain.${SearchEventsEnum.OnsiteSearch}`,
};
const TEALIUM_EVENT_TOPICS: SubscriptionRequest[] = [
  {
    topic: TEALIUM_TOPICS.cart_addition,
    version: CartEventsVersionsEnum['1.0.0'],
  },

  {
    topic: TEALIUM_TOPICS.cart_remove,
    version: CartEventsVersionsEnum['1.0.0'],
  },
  {
    topic: TEALIUM_TOPICS.cart_view,
    version: CartEventsVersionsEnum['1.0.0'],
  },
  {
    topic: TEALIUM_TOPICS.shopping_list_addition,
    version: CartEventsVersionsEnum['1.0.0'],
  },
  {
    topic: TEALIUM_TOPICS.shopping_list_remove,
    version: CartEventsVersionsEnum['1.0.0'],
  },
  {
    topic: TEALIUM_TOPICS.proceed_to_checkout,
    version: CartEventsVersionsEnum['1.0.0'],
  },
  {
    topic: TEALIUM_TOPICS.abo_registration_start,
    version: '1.0.0',
  },
  {
    topic: TEALIUM_TOPICS.customer_registration_start,
    version: '1.0.0',
  },
  {
    topic: TEALIUM_TOPICS.member_registration_start,
    version: '1.0.0',
  },
  {
    topic: TEALIUM_TOPICS.sharebar_publish,
    version: ShareEventsVersionsEnum['1.0.0'],
  },
  {
    topic: TEALIUM_TOPICS.onsite_search,
    version: SearchEventsVersionsEnum['1.0.0'],
  },
];

export default TEALIUM_EVENT_TOPICS;
