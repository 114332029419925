import { ConfigService } from '@nextgen-web-framework/core';
import masterDataFactory from '../masterData';

type KeyType = keyof typeof masterDataFactory;

const getMasterData = (dependency: string, locale?: string) => {
  const configs = ConfigService.getConfigs();
  const fileNameWithTenant = `data-${configs.NEXT_PUBLIC_TENANT_NAME}`;
  const defaultFileName = 'data';

  const keyWithTenantAndLocalization = `${dependency}/${fileNameWithTenant}_${locale}.json` as KeyType;
  const keyWithDefaultTenantFileName = `${dependency}/${fileNameWithTenant}.json` as KeyType;

  const keyWithoutTenantAndLocalization = `${dependency}/${defaultFileName}_${locale}.json` as KeyType;
  const keyWithDefaultFileName = `${dependency}/${defaultFileName}.json` as KeyType;

  if (masterDataFactory[keyWithTenantAndLocalization]) {
    return masterDataFactory[keyWithTenantAndLocalization];
  } else if (masterDataFactory[keyWithDefaultTenantFileName]) {
    return masterDataFactory[keyWithDefaultTenantFileName];
  } else if (masterDataFactory[keyWithoutTenantAndLocalization]) {
    return masterDataFactory[keyWithoutTenantAndLocalization];
  }
  return masterDataFactory[keyWithDefaultFileName];
};

export default getMasterData;
