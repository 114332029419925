import { Logger } from '@nextgen-web-framework/core';
import { createContext, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import logObject from '../../utils/logObject';
import { NextgenIntlProviderWrapperProps } from './types/propsTypes';

const logger = Logger.getInstance(logObject('NextgenIntlProviderWrapper'));

const NextgenIntlProviderContext = createContext<{
  updateMessages: (messagesToAppend: Record<string, string>) => void;
}>({
  updateMessages: (messagesToAppend: Record<string, string>) => {
    logger.log(`messagesToAppend: ${messagesToAppend}`);
  },
});

const NextgenIntlProviderWrapper = ({ messages, locale, children }: NextgenIntlProviderWrapperProps) => {
  const [resolvedMessages, setResolvedMessages] = useState<Record<string, string>>(messages);

  const updateMessages = (messagesToAppend: Record<string, string>) => {
    setResolvedMessages({
      ...resolvedMessages,
      ...messagesToAppend,
    });
  };

  return (
    <NextgenIntlProviderContext.Provider value={{ updateMessages }}>
      <IntlProvider locale={locale} messages={resolvedMessages}>
        {children}
      </IntlProvider>
    </NextgenIntlProviderContext.Provider>
  );
};

export const useNextgenIntlUpdate = () => useContext(NextgenIntlProviderContext);

export default NextgenIntlProviderWrapper;
