import { linkEventsRegistry } from '@nextgen-web-framework/all';
import { EventNameEnum } from './types/propTypes';
import { TEALIUM_TOPICS } from './utils/constant';

const updateLinkEventsMappingRegistry = () => {
  linkEventsRegistry[TEALIUM_TOPICS.cart_addition] = EventNameEnum.cart_addition;
  linkEventsRegistry[TEALIUM_TOPICS.cart_remove] = EventNameEnum.cart_remove;
  linkEventsRegistry[TEALIUM_TOPICS.cart_view] = EventNameEnum.cart_view;
  linkEventsRegistry[TEALIUM_TOPICS.shopping_list_addition] = EventNameEnum.shopping_list_addition;
  linkEventsRegistry[TEALIUM_TOPICS.shopping_list_remove] = EventNameEnum.shopping_list_remove;
  linkEventsRegistry[TEALIUM_TOPICS.proceed_to_checkout] = EventNameEnum.proceed_to_checkout;
  linkEventsRegistry[TEALIUM_TOPICS.abo_registration_start] = EventNameEnum.abo_registration_start;
  linkEventsRegistry[TEALIUM_TOPICS.customer_registration_start] = EventNameEnum.customer_registration_start;
  linkEventsRegistry[TEALIUM_TOPICS.member_registration_start] = EventNameEnum.member_registration_start;
  linkEventsRegistry[TEALIUM_TOPICS.sharebar_publish] = EventNameEnum.sharebar_publish;
  linkEventsRegistry[TEALIUM_TOPICS.onsite_search] = EventNameEnum.onsite_search;
};

export default updateLinkEventsMappingRegistry;
