export const checkIsIOS = (): boolean => {
  let isIOS = false,
    msStreamExists = false;

  if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
    isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    msStreamExists = typeof (window as { MSStream?: unknown }).MSStream !== 'undefined';
  }

  return isIOS && !msStreamExists;
};
