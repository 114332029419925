import { IProduct } from '@commerce-product-webcomponents/sdk';

const ProductViewTransformer = <T>(data: T) => {
  const productData = data as IProduct;
  const transformedData = {
    product_brand: [productData.brandName],
    product_id: [productData.productId],
    product_inventoryStatus: [productData.derivedAvailabilityStatus],
    product_name: [productData.productName],
    product_promo: ['none'],
  };
  return transformedData;
};

export default ProductViewTransformer;
