import { SearchViewTransformerProps } from './types/propTypes';

const SearchViewTransformer = <T>(data: T) => {
  const { searchResults, text, search_type } = data as SearchViewTransformerProps;
  const transformedData = {
    search_category: ['products'],
    search_keyword: text,
    search_type,
    search_results: [searchResults?.total?.toString()],
  };
  return transformedData;
};

export default SearchViewTransformer;
