import { useEffect, useState } from 'react';
import { useSession } from '@nextgen-web-framework/all';
import { MultiAccountSelector } from '@commerce-identity-webcomponents/ui';
import { cleanUpCartLS } from '@commerce-cart-webcomponents/sdk';
import { MultipleAccountSelectorProps } from './types/propsTypes';

const MultipleAccountSelector = (props: MultipleAccountSelectorProps) => {
  const { session } = useSession();
  const [showModal, setShowModal] = useState(false);
  const selectedAccount = props?.isAccountSelected;

  useEffect(() => {
    if (selectedAccount === 'true') {
      storeAccountSelection();
    } else {
      const hasSelectedAccount = localStorage.getItem('hasSelectedAccount');
      if (!hasSelectedAccount && session?.accounts && session?.accounts.length > 1) {
        setShowModal(true);
      }
    }
  }, [session.accounts, selectedAccount]);

  const handleAccountSelection = () => {
    storeAccountSelection();
    cleanUpCartLS();
    location.reload();
  };

  const storeAccountSelection = () => {
    localStorage.setItem('hasSelectedAccount', 'true');
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <MultiAccountSelector
          accountList={session?.accounts}
          onClose={() => null}
          closeIcon={false}
          callback={handleAccountSelection}
        />
      )}
    </>
  );
};

export default MultipleAccountSelector;
