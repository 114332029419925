const MarketExtensionsPath = `./market-extensions/${process.env.NEXT_PUBLIC_TENANT_NAME ?? 'global'}`;
// eslint-disable-next-line import/no-dynamic-require, @typescript-eslint/no-var-requires
const localesData = require(`${MarketExtensionsPath}/data.json`).locales;

export const i18n = {
  defaultLocale: localesData.defaultLocale,
  locales: Object.keys(localesData.mappedLocales),
};

//locale type for application use
export type Locale = (typeof i18n)['locales'][number];

export const getLocale = (currentLocale: string): string => {
  const defaultLocale = localesData.mappedLocales[localesData.defaultLocale];
  if (!currentLocale) {
    return defaultLocale;
  }
  const arr = currentLocale.split('-');
  if (arr.length === 2) {
    return currentLocale;
  }
  return localesData.mappedLocales[currentLocale] ?? defaultLocale;
};
