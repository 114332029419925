import { useEffect } from 'react';
import { getMeData, getBannerData, getNotificationData } from '@commerce-webcomponents/sdk';
import type { AnyAction } from '@reduxjs/toolkit';
import { getFulfillmentTypesSliceReq } from '@commerce-checkout-webcomponents/sdk';
import { useSession, useNextgenRouter, UserType, useSessionUpdate } from '@nextgen-web-framework/all';
import type { Session } from '@nextgen-web-framework/core';
import { useAppDispatch } from '../../feature/store';
import { compareSession } from 'utils/getInitialSession';

export interface SessionRefresherProps {
  children?: React.ReactNode;
  initialSession?: Session;
}

const SessionRefresher = (props: SessionRefresherProps) => {
  const dispatch = useAppDispatch();
  const router = useNextgenRouter();
  const { session } = useSession();
  const updateSession = useSessionUpdate();

  // Check if cookie is not in sync with session
  if (session.type && props.initialSession && !compareSession(props.initialSession, session)) {
    updateSession(props.initialSession);
  }
  useEffect(() => {
    if (router.params?.locale) {
      dispatch(getNotificationData({ language: router.params?.locale as string }));
    }
  }, []);
  useEffect(() => {
    if (router.params?.locale) {
      dispatch(getBannerData({ language: router.params?.locale as string }));
    }
  }, []);

  useEffect(() => {
    if (session.type) {
      dispatch(getFulfillmentTypesSliceReq() as unknown as AnyAction);
    }
  }, [session.type]);
  useEffect(() => {
    if (session.type && router.params?.locale) {
      dispatch(getMeData({ userType: session.type as UserType }));
    }
  }, [session.type, router.params?.locale]);
  return <>{props.children}</>;
};

export default SessionRefresher;
