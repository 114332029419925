import { CheckoutProfileService, checkoutApi } from '@commerce-checkout-webcomponents/sdk';
import type { CheckoutProfile, CheckoutProfileResponse } from '@commerce-checkout-webcomponents/sdk';
import { useEffect } from 'react';
import { Logger, StorageService, ConfigService } from '@nextgen-web-framework/core';
import { useNextgenRouter, useSession, useSessionUpdate } from '@nextgen-web-framework/all';
import { useAddressUpdate } from '@commerce-webcomponents/ui';
import { storeCheckoutProfile } from '@commerce-webcomponents/ui-composite';
import { LocalStorageKeys } from 'types/common';
import logObject from 'utils/logObject';
import getUpdatedCheckoutProfileWithDefaultAddress from 'utils/getUpdatedCheckoutProfile';

const logger = Logger.getInstance(logObject('UserCheckoutProfile.tsx', 'UserCheckoutProfile'));

export interface CheckoutProfileProps {
  children?: React.ReactNode;
}

const UserCheckoutProfile = (props: CheckoutProfileProps) => {
  const updateSession = useSessionUpdate();
  const config = ConfigService.getConfigs();
  const localstorage = StorageService.getLocalStorage();
  const { session, isAnonymous } = useSession();
  const { updateAddress } = useAddressUpdate();
  const router = useNextgenRouter();

  const getLocalStorageData = (key: string) => localstorage.retrieve(key);
  const updateCheckoutProfileData = async (checkoutProfileData: CheckoutProfileResponse) => {
    const updatedCheckoutProfileData = checkoutProfileData as CheckoutProfile;
    const { serviceLevel } = updatedCheckoutProfileData.fulfillmentType ?? {};
    if (!serviceLevel?.deliveryAddress && !isAnonymous()) {
      return await getUpdatedCheckoutProfileWithDefaultAddress(updatedCheckoutProfileData);
    }
    return updatedCheckoutProfileData;
  };

  useEffect(() => {
    if (router.params?.locale) {
      CheckoutProfileService.updateSelectedServiceLocation();
    }
  }, [router.params?.locale]);

  const updateProfileOnTypeChange = async () => {
    try {
      const localStorageUserType = getLocalStorageData(LocalStorageKeys.USER_TYPE);
      // Check if the user type has changed and, if so, fetch new data for the user based on the updated user type.

      if (localStorageUserType !== session.type) {
        let checkoutProfileData = await checkoutApi.getCheckoutProfile();
        if (!('error' in checkoutProfileData)) {
          // Check if the object is of type CheckoutProfileData update with warehouse id
          checkoutProfileData = await updateCheckoutProfileData(checkoutProfileData);
          storeCheckoutProfile(checkoutProfileData as CheckoutProfile, updateSession, updateAddress);
          localstorage.store(LocalStorageKeys.USER_TYPE, session.type ?? '');
        }
      }
    } catch (error) {
      logger.error('fetching checkoutProfileData', error);
      localstorage.store(LocalStorageKeys.WAREHOUSE_ID, `${config.NEXT_PUBLIC_WAREHOUSE_ID}`);
    }
  };

  useEffect(() => {
    updateProfileOnTypeChange();
  }, []);

  useEffect(() => {
    //  once we have actual fulfillment API will call here
    if (!getLocalStorageData(LocalStorageKeys.WAREHOUSE_ID)) {
      localstorage.store(LocalStorageKeys.WAREHOUSE_ID, `${config.NEXT_PUBLIC_WAREHOUSE_ID}`);
    }
  }, []);

  return <>{props.children}</>;
};

export default UserCheckoutProfile;
