import { SharebarEventDataInterface } from './types/propTypes';

const SharebarTransformer = <T>(data: T) => {
  const sharebarEventData = data as SharebarEventDataInterface;
  const transformedData = {
    page_section: sharebarEventData.page_section,
    product_businessVolume: sharebarEventData.product_businessVolume,
    share_channel: sharebarEventData.share_channel,
    product_id: sharebarEventData.product_id,
    product_brand: sharebarEventData.product_brand,
    product_name: sharebarEventData.product_name,
    product_pointValue: sharebarEventData.product_pointValue,
    product_unitPrice: sharebarEventData.product_unitPrice,
  };
  return transformedData;
};

export default SharebarTransformer;
