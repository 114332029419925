import { Product } from '@commerce-search-webcomponents/sdk';
import { ProductListTransformedData } from './types/propTypes';

const ProductListViewTransformer = <T>(data: T) => {
  const productData = data as Array<Product>;
  const transformedData: ProductListTransformedData = {
    product_brand: [],
    product_id: [],
    product_name: [],
    product_inventoryStatus: [],
    product_promo: [],
  };
  productData?.forEach((product: Product) => {
    transformedData['product_brand'] = [...transformedData['product_brand'], product?.copy?.brandName || ''];
    transformedData['product_id'] = [...transformedData['product_id'], product?.id || ''];
    transformedData['product_name'] = [...transformedData['product_name'], product?.name || ''];
    transformedData['product_promo'] = [...transformedData['product_promo'], 'none'];
    transformedData['product_inventoryStatus'] = [
      ...transformedData['product_inventoryStatus'],
      productData[0].items?.[0]?.inventory?.[0]?.derivedAvailabilityStatus || '',
    ];
  });
  return transformedData;
};

export default ProductListViewTransformer;
